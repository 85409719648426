<!-- 综合处理方案 -->
<template>
    <div>
      <div style="height: 80px;width:100%;background-color: #fff;"></div>
    <div class="synopsis-page">
        <div class="left-nav">
          <ul>
            <li><div @click="getRouter1">综合处理方案</div></li>
            <li><div @click="getRouter2">模块化处理方案</div></li>
            <li>
              <div @click="getRouter5">智慧收运信息监管平台-eDIS</div>
            </li>
            <li>
              <div @click="getRouter3">3D数字孪生系统-eTD</div>
            </li>
            <li><div @click="getRouter4">生态农业处理方案</div></li>
          </ul>
        </div>
      <div class="font"></div>
      <!-- 综合处理方案 -->
        <div class="section" v-if="id=='1'">
        <div class="synopsis">
          <h2>综合处理方案</h2>  
        </div>
        <div class="synopsis-text">
          <p>
            适用于日处理量在50t~500t的餐厨、厨余、果蔬等有机垃圾的处理。所建设的项目设备科学先进，工艺路线完整，配套设施齐全，资源利用程度高。</p>    
             <div class="img-title">
              <img src="../../assets/images/home/zh1.webp" style="margin-top:10px;"/>                
              <img src="../../assets/images/home/zh.webp" style="margin-top:10px;" />
            </div>
          </div>
      </div>

      <!-- 模块化处理终端 -->
      <div class="section1" v-if="id=='2'">
          <div class="synopsis">
            <h2>模块化处理终端</h2>
          </div>
          <div class="synopsis-text">
            <p>
                处理量在 5t-100t 的餐厨、厨余、果蔬等有机垃圾的处理，可根据处理量、使用场所较小等条件，选择不同的处理模块和组合，对场地要求不高，占地面积小，可室内和室外使用；运输、安装方便，操作简便，自动化程度高，可远程控制。分布式方案系统由投料模块、干湿分离模块、热解模块、分离模块、水处理模块、堆肥模块及配套储罐组成，各模块组成可根据客户需求灵活选配。</p>    
                <div class="img-title">
                  <img src="../../assets/images/solution/101.png" style="width:500px;height:700px;"/>
              
                
                </div>
            </div>
        </div>

        <!-- 3D数字孪生 -->
        <div class="section" v-if="id=='3'">
        <div class="synopsis">
          <h2>3D数字孪生系统-eTD</h2>
        </div>
        <div class="synopsis-text">
          <p>
            针对有机垃圾全程监管的特点，我司独立自主研发了3D数字孪生技术、智慧收运数字化平台。实现了数据的高效、安全、稳定的计算和存储服务，减少管理和运营的风险。该项技术已率先成功运用于餐厨项目，处于行业领先。
          </p>
          <div class="img-title">
          
          <img src="../../assets/images/solution/3D1.png" style="width:600px" />
          <img src="../../assets/images/solution/3D2.jpg" style="width:600px"/>
          </div>
        </div>
      </div>

      <!-- 生态农业模块 -->
      <div class="section2" v-if="id=='4'">
        <div class="synopsis">
          <h2>生态农业模块</h2>
        </div>
        <div class="synopsis-text">
          <p>
            该示范基地以“科技生态循环”为理念，坚持可持续农业生产、资源利用和环境保护的目标，打造多个主题生态农业科技馆，全方面的综合利用与宣教相结合，打造新型现代农业生态循环示范产业园。
          </p>

          <div class="img-title">
            <img src="../../assets/images/project/xishan-3.webp" style="margin: 10px;height: 500px;" />
            <img src="../../assets/images/project/xishan-4.webp" style="margin: 10px;height: 500px;" />
          </div>
        </div>
      </div>

      <!-- 智慧收运信息监管平台-eDIS -->
      <div class="section" v-if="id=='5'">
         <div class="synopsis">
           <h2>智慧收运信息监管平台-eDIS</h2>
         </div>
         <div class="synopsis-text">
           <p>
            针对有机废弃物处理，专业研发完整的信息流平台，将废弃物点源收集、运输到后端处理处置的全流程进行监管。
           </p>
           <img src="../../assets/images/solution/tupian0.png" style="width:1000px">
           <div class="img-title">
           
           <img src="../../assets/images/solution/图片30.png" style="width:600px" />
           <img src="../../assets/images/solution/图片31.png" style="width:600px"/>
           </div>
         </div>
       </div>

      </div>
    </div>

</template>
<script>
export default {
  data() {
    return {
        id:'',
    };
  },
  watch: {
	// 监听路由是否变化
	'$route' (to, from) { 
		if(to.query.id != from.query.id){
		    this.id = to.query.id; // 把最新id赋值给定义在data中的id
			this.init(); // 重新调用加载数据方法
		}
	}
},
mounted(){
    this.id = this.$route.query.id;
  },
  created() {
    setTimeout(()=>{
      this.id = this.$route.query.id;
    })
},
methods: {
    getRouter2() {
      this.$router.push({
        path: "./solutionDetail",
        query: {
          id: 2,
        },
      });
    },
    getRouter1() {
      this.$router.push({
        path: "./solutionDetail",
        query: {
          id: 1,
        },
      });
    },
    getRouter3() {
      this.$router.push({
        path: "./solutionDetail",
        query: {
          id: 3,
        },
      });
    },
    getRouter4() {
      this.$router.push({
        path: "./solutionDetail",
        query: {
          id: 4,
        },
      });
    },
    getRouter5() {
      this.$router.push({
        path: "./solutionDetail",
        query: {
          id: 5,
        },
      });
    },
},
};
</script>
<style scoped>
.section2 {
  width: 1200px;
  height: 800px;
  overflow: hidden;
  margin: auto;
}
  .section1 {
    width: 1200px;
    overflow: hidden;
    padding: 30px 0;
    margin: auto;
    height: 950px;
  }
.img-title{
  width: 1070px;
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.img-title img{
  width: 48%;
  margin-right:1%;
  margin-left:1%;
  height: 420px;
}
  .synopsis-page{
    overflow: hidden;
    min-width: 1250px;
height: 100%;
margin: auto;
padding: 0;
background: #fff;
  }

.synopsis-text {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
}
.synopsis-text p {
  line-height: 35px;
  font-size: 15px;
  text-indent: 2em;
  text-align: left;
}
.left-nav {
padding: 20px 0;
height: 40px;
position: relative;
left:20%;
line-height: 40px;
margin-left: 00px;
margin-top: 15px;
}
.font {
width: 1200px;
position: relative;
left:20%;
padding: 40px 0 10px 20px;
border-bottom: 1px solid #eee;
}
.left-nav ul li {
display: block;
float: left;
font-size: 20px;
margin-left: 20px;
text-align: center;
}
.left-nav ul li div {
display: block;
padding: 0 20px;
background: #8cc6ee;
border: 1px solid white;
color: #fff;
}

.section {
  width: 1200px;
  overflow: hidden;
  padding: 30px 0;
  margin: auto;
}
.synopsis {
  text-align: center;
  width: 100%;
}
.synopsis i {
  display: block;
  width: 2px;
  height: 32px;
  margin: auto;
  background: #b5b5b5;
}
.synopsis h2 {
  font-size: 31px;
  color: #3b3b3b;
  padding: 5px 0;
}
.synopsis em {
  display: block;
  width: 37px;
  height: 4px;
  margin: auto;
  background: #76c817;
}
.synopsis p {
  color: #6c6c6c;
  font-size: 14px;
  padding: 5px 0;
  text-transform: uppercase;
}
</style>
