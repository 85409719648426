var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"height":"80px","width":"100%","background-color":"#fff"}}),_c('div',{staticClass:"synopsis-page"},[_c('div',{staticClass:"left-nav"},[_c('ul',[_c('li',[_c('div',{on:{"click":_vm.getRouter1}},[_vm._v("综合处理方案")])]),_c('li',[_c('div',{on:{"click":_vm.getRouter2}},[_vm._v("模块化处理方案")])]),_c('li',[_c('div',{on:{"click":_vm.getRouter5}},[_vm._v("智慧收运信息监管平台-eDIS")])]),_c('li',[_c('div',{on:{"click":_vm.getRouter3}},[_vm._v("3D数字孪生系统-eTD")])]),_c('li',[_c('div',{on:{"click":_vm.getRouter4}},[_vm._v("生态农业处理方案")])])])]),_c('div',{staticClass:"font"}),(_vm.id=='1')?_c('div',{staticClass:"section"},[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.id=='2')?_c('div',{staticClass:"section1"},[_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.id=='3')?_c('div',{staticClass:"section"},[_vm._m(4),_vm._m(5)]):_vm._e(),(_vm.id=='4')?_c('div',{staticClass:"section2"},[_vm._m(6),_vm._m(7)]):_vm._e(),(_vm.id=='5')?_c('div',{staticClass:"section"},[_vm._m(8),_vm._m(9)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"synopsis"},[_c('h2',[_vm._v("综合处理方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"synopsis-text"},[_c('p',[_vm._v(" 适用于日处理量在50t~500t的餐厨、厨余、果蔬等有机垃圾的处理。所建设的项目设备科学先进，工艺路线完整，配套设施齐全，资源利用程度高。")]),_c('div',{staticClass:"img-title"},[_c('img',{staticStyle:{"margin-top":"10px"},attrs:{"src":require("../../assets/images/home/zh1.webp")}}),_c('img',{staticStyle:{"margin-top":"10px"},attrs:{"src":require("../../assets/images/home/zh.webp")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"synopsis"},[_c('h2',[_vm._v("模块化处理终端")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"synopsis-text"},[_c('p',[_vm._v(" 处理量在 5t-100t 的餐厨、厨余、果蔬等有机垃圾的处理，可根据处理量、使用场所较小等条件，选择不同的处理模块和组合，对场地要求不高，占地面积小，可室内和室外使用；运输、安装方便，操作简便，自动化程度高，可远程控制。分布式方案系统由投料模块、干湿分离模块、热解模块、分离模块、水处理模块、堆肥模块及配套储罐组成，各模块组成可根据客户需求灵活选配。")]),_c('div',{staticClass:"img-title"},[_c('img',{staticStyle:{"width":"500px","height":"700px"},attrs:{"src":require("../../assets/images/solution/101.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"synopsis"},[_c('h2',[_vm._v("3D数字孪生系统-eTD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"synopsis-text"},[_c('p',[_vm._v(" 针对有机垃圾全程监管的特点，我司独立自主研发了3D数字孪生技术、智慧收运数字化平台。实现了数据的高效、安全、稳定的计算和存储服务，减少管理和运营的风险。该项技术已率先成功运用于餐厨项目，处于行业领先。 ")]),_c('div',{staticClass:"img-title"},[_c('img',{staticStyle:{"width":"600px"},attrs:{"src":require("../../assets/images/solution/3D1.png")}}),_c('img',{staticStyle:{"width":"600px"},attrs:{"src":require("../../assets/images/solution/3D2.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"synopsis"},[_c('h2',[_vm._v("生态农业模块")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"synopsis-text"},[_c('p',[_vm._v(" 该示范基地以“科技生态循环”为理念，坚持可持续农业生产、资源利用和环境保护的目标，打造多个主题生态农业科技馆，全方面的综合利用与宣教相结合，打造新型现代农业生态循环示范产业园。 ")]),_c('div',{staticClass:"img-title"},[_c('img',{staticStyle:{"margin":"10px","height":"500px"},attrs:{"src":require("../../assets/images/project/xishan-3.webp")}}),_c('img',{staticStyle:{"margin":"10px","height":"500px"},attrs:{"src":require("../../assets/images/project/xishan-4.webp")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"synopsis"},[_c('h2',[_vm._v("智慧收运信息监管平台-eDIS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"synopsis-text"},[_c('p',[_vm._v(" 针对有机废弃物处理，专业研发完整的信息流平台，将废弃物点源收集、运输到后端处理处置的全流程进行监管。 ")]),_c('img',{staticStyle:{"width":"1000px"},attrs:{"src":require("../../assets/images/solution/tupian0.png")}}),_c('div',{staticClass:"img-title"},[_c('img',{staticStyle:{"width":"600px"},attrs:{"src":require("../../assets/images/solution/图片30.png")}}),_c('img',{staticStyle:{"width":"600px"},attrs:{"src":require("../../assets/images/solution/图片31.png")}})])])
}]

export { render, staticRenderFns }